import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "reactstrap"
import Auxiliary from "util/Auxiliary"
import { formatNumber, msToHMS } from "util/common"
import {
  Table,
  TableCell,
  Paper,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material"
import { Pagination, Tag } from "antd"
import {
  diamondsByUsers,
  getLiveCallingLogData,
  getLiveCallingUserData,
} from "clientServices/liveCallingService"
import Chips from "components/CommonFilter/chips"
import { useSelector, useDispatch } from "react-redux"
import { Rating } from "@mui/material"
import { getLocationData } from "appRedux/slices/location"
import { capitalizeFirstLetter } from "util/common"

export default function CreatorStats(props) {
  const urlQueryParams = new URLSearchParams(props.location.search)
  const user_uuid = urlQueryParams.get("userUUID") || ""
  const initialFilters = {
    pageSize: urlQueryParams.get("pageSize") || 10,
    currentPage: urlQueryParams.get("currentPage") || 1,
    createdDateFrom: urlQueryParams.get("createdDateFrom") || "",
    createdDateTo: urlQueryParams.get("createdDateTo") || "",
    userUUID: user_uuid,
  }

  const [filters, setFilters] = useState(initialFilters)
  const [userUUid, setUserUUID] = useState(user_uuid)
  const [userInfo, setUserInfo] = useState(null)
  const [diamondInfo, setDiamondInfo] = useState(null)
  const [userInfoLog, setUserInfoLog] = useState(null)
  const [loading, setLoading] = useState(false)

  const [showFilter, setShowFilter] = useState(false)

  const dispatch = useDispatch()

  const locationData = useSelector(state => state.locationReducer.locationList)

  const interestList = {
    yoga: "Yoga",
    gym: "Gym",
    fish_lover: "Fish lover",
    dog: "Dog",
    cat: "Cat",
    beach_lover: "Beach lover",
    mountain_lover: "Mountain lover",
    road_trips: "Road trips",
    casual_friendship: "Casual friendship",
    casual_relationship: "Casual relationship",
    astrology: "Astrology",
    dancing: "Dancing",
    singing: "Singing",
    reading_books: "Reading books",
    hiking: "Hiking",
    camping: "Camping",
    cooking: "Cooking",
    beer_lover: "Beer lover",
    writing: "Writing",
    video_games: "Video Games",
    chai_lover: "Chai lover",
    coffee_lover: "Coffee lover",
    horror_movies: "Horror movies",
    romantic_movies: "Romantic movies",
    comedy_movies: "Comedy movies",
    gardening: "Gardening",
    photography: "Photography",
    shopping: "Shopping",
    football: "Football",
    cricket: "Cricket",
    hockey: "Hockey",
    swimming: "Swimming",
  }

  const [cityLocationList, setCityLocationList] = useState({})

  const _getModerationImage = props => {
    return userInfo?.profile_pic
  }

  useEffect(() => {
    if (userUUid) {
      fetchAndSetUserData(userUUid)
    }
  }, [loading])

  useEffect(() => {
    dispatch(getLocationData())
  }, [dispatch])

  useEffect(() => {
    if (locationData?.length) {
      const cityLocationListObj = locationData.reduce((acc, location) => {
        acc[location.dhTagId] = capitalizeFirstLetter(location.name)
        return acc
      }, {})
      setCityLocationList(cityLocationListObj)
    }
  }, [locationData])

  const fetchAndSetUserData = async userID => {
    const res = await getLiveCallingUserData({ filters: { id: userID } })
    setUserInfo(res.data[0])
    const { userUUID, ...apiFilters } = filters
    const log = await getLiveCallingLogData({
      filters: { id: userID, ...apiFilters },
    })
    setUserInfoLog({
      ...log.data,
      ...{ total: log.total },
      ...{ logData: log.itemData?.data },
      ...{ acceptanceStats: log.acceptanceStats },
    })
    const diamondsPerUser = await diamondsByUsers([userID])
    setDiamondInfo(diamondsPerUser[userID])
    setLoading(false)
  }

  const handleModal = status => {
    setShowAddVideosPopup(status)
  }

  const _onPageChange = async value => {
    setLoading(true)
    const { userUUID, ...newFilters } = filters
    newFilters["currentPage"] = value
    setFilters(newFilters)
    let log = await getLiveCallingLogData({
      filters: { id: userUUid, ...newFilters },
    })
    setUserInfoLog({
      ...log.data,
      ...{ total: log.total },
      ...{ logData: log.itemData?.data },
      ...{ acceptanceStats: log.acceptanceStats },
    })
    updateURLParams(newFilters)
  }

  const _toggleFilters = () => {
    setShowFilter(!showFilter)
  }

  const _closeFilters = async () => {
    setLoading(true)
    const newFilters = _.cloneDeep(filters)
    newFilters["currentPage"] = 1
    setFilters(newFilters)
    setShowFilter(false)
    updateURLParams(newFilters)
  }

  const updateURLParams = newFilters => {
    const queryParams = new URLSearchParams()

    // Set pagination values
    queryParams.set("currentPage", String(newFilters.currentPage))
    queryParams.set("pageSize", String(newFilters.pageSize))

    // Add all the other active filters to the URL
    Object.keys(newFilters).forEach(key => {
      const value = newFilters[key]
      if (value) {
        queryParams.set(key, String(value))
      } else {
        queryParams.delete(key) // Remove from URL if filter is unset
      }
    })
    props.history.push(`${location.pathname}?${queryParams.toString()}`)
  }
  const _removeFilter = async filterKey => {
    let newFilters = _.cloneDeep(filters)
    if (filterKey === "userUUID") {
      newFilters = {
        pageSize: 10,
        currentPage: 1,
        createdDateFrom: "",
        createdDateTo: "",
        userUUID: "",
      }
      setUserUUID("")
      setUserInfo(null)
      setDiamondInfo(null)
      setUserInfoLog(null)
      setFilters(newFilters)
      // setLoading(true)
    } else {
      newFilters = _.cloneDeep(filters)
      _.unset(newFilters, filterKey)
      newFilters["currentPage"] = 1
      setFilters(newFilters)
      setLoading(true)
    }
    updateURLParams(newFilters)
  }

  const _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(filters)
    newFilters[type] = value
    newFilters["currentPage"] = 1
    if (type === "userUUID") {
      setUserUUID(value)
    }
    setFilters(newFilters)
  }
  return (
    <Auxiliary loading={loading}>
      <Row>
        <Chips
          showFilters={showFilter}
          filters={filters}
          search={_closeFilters}
          removeFilter={_removeFilter}
          handleFilterChange={_handleFilterChange}
          type="creator_stats"
          toggleFilters={_toggleFilters}
        />
      </Row>
      {userInfo ? (
        <>
          <Row>
            <Col lg="4">
              <Card className="p-3">
                <div
                  className="d-flex align-items-center flex-column"
                  title={userInfoLog?.acceptanceStats?.call_acceptance_label}
                >
                  <h3>Call Acceptance Rate</h3>
                  <h4>
                    {userInfoLog?.acceptanceStats?.call_acceptance_rate
                      ? (
                          userInfoLog?.acceptanceStats?.call_acceptance_rate *
                          100
                        ).toFixed(2) + " %"
                      : "0%"}
                  </h4>
                </div>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="p-3">
                <div className="d-flex align-items-center flex-column">
                  <h3>Total Diamonds Earned</h3>
                  <h4>
                    {formatNumber(diamondInfo?.total_diamonds_count || 0)}
                  </h4>
                </div>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="p-3">
                <div className="d-flex align-items-center flex-column">
                  <h3>Today Earning Diamonds</h3>
                  <h4>{formatNumber(diamondInfo?.today_earning || 0)}</h4>
                </div>
              </Card>
            </Col>
          </Row>

          <div
            className="created-by"
            style={{
              fontWeight: "700",
              padding: "25px",
              backgroundColor: "#fff",
              marginBottom: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                marginBottom: "20px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  marginLeft: "20px",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={_getModerationImage(props)}
                  className="rounded-circle avatar-xs"
                  style={{ height: "3rem", width: "3rem" }}
                  alt="user-pic"
                />
                <div
                  style={{
                    fontSize: "14px",
                    marginLeft: "10px",
                    marginTop: "4px",
                  }}
                >
                  <b>{userInfo?.user_name}</b>
                  <div
                    style={{
                      fontSize: "14px",
                      marginLeft: "10px",
                      marginTop: "2px",
                    }}
                  >
                    {userInfo?.created_date
                      ?.split("T")[0]
                      .split("-")
                      .reverse()
                      .join("/")}
                    , {userInfo?.created_date?.split("T")[1].split("+")[0]}
                  </div>
                </div>
                {userInfo?.avg_rating && (
                  <div
                    style={{
                      margin: "auto",
                      marginLeft: "20px",
                      display: "flex",
                    }}
                  >
                    <Rating
                      name="half-rating"
                      defaultValue={userInfo?.avg_rating}
                      precision={0.1}
                      readOnly
                    />{" "}
                    <div style={{ marginTop: "2px", marginLeft: "5px" }}>
                      ({userInfo?.avg_rating}) - ({userInfo?.total_rating_count}
                      ) <span style={{ fontSize: "10px" }}>Rating</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center font-size-14 justify-content-between">
              <div
                style={{
                  fontSize: "18px",
                  marginTop: "20px",
                  marginLeft: "1rem",
                }}
              >
                Creator Details
              </div>
            </div>
            <div className="grid-container">
              <div className="created-by">
                <div className="d-flex align-items-center font-size-14 justify-content-between">
                  <div>Age</div>
                  <div style={{ fontWeight: "600" }}>{userInfo?.age}</div>
                </div>
              </div>
              <div className="created-by">
                <div className="d-flex align-items-center font-size-14 justify-content-between">
                  <div>Gender</div>
                  <div style={{ fontWeight: "600" }}>{userInfo?.gender}</div>
                </div>
              </div>
              <div className="created-by">
                <div className="d-flex align-items-center font-size-14 justify-content-between">
                  <div>Interests</div>
                  <div style={{ fontWeight: "600" }}>
                    {userInfo?.interests
                      ?.map(item => interestList[item])
                      ?.join(", ")}
                  </div>
                </div>
              </div>
              <div className="created-by">
                <div className="d-flex align-items-center font-size-14 justify-content-between">
                  <div>Location</div>
                  <div style={{ fontWeight: "600" }}>
                    {Array.isArray(userInfo?.location) && userInfo?.location[0]
                      ? cityLocationList[userInfo?.location[0]] || ""
                      : "Location Not Available"}
                  </div>
                </div>
              </div>
              <div className="created-by">
                <div className="d-flex align-items-center font-size-14 justify-content-between">
                  <div>Price/min</div>
                  <div style={{ fontWeight: "600" }}>
                    {userInfo?.audio_call?.discount_price}
                  </div>
                </div>
              </div>
              <div className="created-by">
                <div className="d-flex align-items-center font-size-14 justify-content-between">
                  <div>Agency ID</div>
                  <div style={{ fontWeight: "600" }}>{userInfo?.agency_id}</div>
                </div>
              </div>
              <div className="created-by">
                <div className="d-flex align-items-center font-size-14 justify-content-between">
                  <div>Eligible for free calls</div>
                  <div style={{ fontWeight: "600" }}>
                    {userInfo?.free_call_enabled ? "Y" : "N"}
                  </div>
                </div>
              </div>
              <div className="created-by">
                <div className="d-flex align-items-center font-size-14 justify-content-between">
                  <div>Blue tick enabled</div>
                  <div style={{ fontWeight: "600" }}>
                    {userInfo?.verified ? "Y" : "N"}
                  </div>
                </div>
              </div>
              <div className="created-by">
                <div className="d-flex align-items-center font-size-14 justify-content-between">
                  <div>Call Status</div>
                  <div style={{ fontWeight: "600" }}>
                    <b>{userInfo?.status}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="text-center my-4 h4">
          Select a Creator to view details.
        </div>
      )}

      {userInfoLog?.total > 0 &&
        userInfoLog?.logData &&
        userInfoLog?.logData.length > 0 && (
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <Tag color="geekblue">
              Showing <b>{userInfoLog?.logData?.length}</b> of{" "}
              <b>{formatNumber(userInfoLog.total)}</b> Calls
            </Tag>
          </div>
        )}

      {userInfoLog?.logData && userInfoLog?.logData.length > 0 && (
        <Paper style={{ marginBottom: "20px" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Time</b>
                  </TableCell>
                  <TableCell>
                    <b>Call Format</b>
                  </TableCell>
                  <TableCell>
                    <b>Call Type</b>
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    <b>Caller Id</b>
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    <b>Call Id</b>
                  </TableCell>
                  <TableCell>
                    <b>Duration of Call</b>
                  </TableCell>
                  <TableCell>
                    <b>Free Calls</b>
                  </TableCell>
                  <TableCell>
                    <b>Call End Reason</b>
                  </TableCell>
                  <TableCell>
                    <b>Rating</b>
                  </TableCell>
                  <TableCell>
                    <b>Refunded</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userInfoLog?.logData?.map((items, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {items?.created_date
                        ?.split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")}
                    </TableCell>
                    <TableCell>
                      {items?.created_date?.split("T")[1].split("+")[0]}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {items.comm_type}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {items.call_type}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {items.caller_id}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {items.id}
                    </TableCell>
                    <TableCell>
                      {items.duration ? msToHMS(items.duration) : "0:0:0"}
                    </TableCell>
                    <TableCell>{items.fc_unit}</TableCell>
                    <TableCell>{items.end_reason || "-"}</TableCell>
                    <TableCell>{items.rating || "-"}</TableCell>
                    <TableCell>{items.refund ? "Yes" : "No" || "-"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="d-flex justify-content-end">
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              onChange={_onPageChange}
              defaultCurrent={Number(filters.currentPage)}
              total={userInfoLog?.total ? userInfoLog?.total : 10}
              pageSize={Number(filters.pageSize)}
              showSizeChanger={false}
            />
          </div>
        </Paper>
      )}
    </Auxiliary>
  )
}
