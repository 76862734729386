import React from "react"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"

const { Option } = Select

const AudioCallingDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Agency ID</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["agency_id"]}
                  placeholder={"Agency Id"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value.trim(), "agency_id")
                  }
                  allowClear={true}
                  name="agency_id"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Creator ID</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["user_uuid"]}
                  placeholder={"Creator Id"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value.trim(), "user_uuid")
                  }
                  allowClear={true}
                  name="user_uuid"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Status</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters["status"]}
            placeholder="Status"
            onChange={value => {
              props.handleFilterChange(value, "status")
            }}
            style={{ width: "100%" }}
          >
            <Option value="true">Active</Option>
            <Option value="false">InActive</Option>
          </Select>
        </Col>
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Call Status</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters["call_status"]}
            placeholder="Call Status"
            onChange={value => {
              props.handleFilterChange(value, "call_status")
            }}
            style={{ width: "100%" }}
          >
            <Option value="ONLINE">ONLINE</Option>
            <Option value="OFFLINE">OFFLINE</Option>
            <Option value="BUSY">BUSY</Option>
          </Select>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(AudioCallingDrawer)
