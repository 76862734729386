import React, { useState, useEffect } from "react"
import { Table } from "reactstrap"
import Switch from "react-switch"
import TransferJemsModal from "../../components/JoshReseller/TransferJemsModal"
import {
  getResellerList,
  updateResellerStatus,
} from "clientServices/joshResellerService"
import { Pagination, PaginationProps } from "antd"
import dayjs from "dayjs"
import { useSelector, TypedUseSelectorHook } from "react-redux"
import Auxiliary from "util/Auxiliary"
import { CurentUserRootState, ResellerListAPIResponse } from "util/types/types"

interface Filter {
  currentPage: number
  pageSize: number
}

interface Payload {
  managerId?: string
  filters: Filter
  unassigned?: boolean
}

// Define a typed version of useSelector
const useTypedSelector: TypedUseSelectorHook<CurentUserRootState> = useSelector

interface ResellerProps {
  managerID?: string
}

const Recharge: React.FC<ResellerProps> = ({ managerID = "default" }) => {
  const [loading, setLoading] = useState(false)
  const [resellerList, setResellerList] = useState<ResellerListAPIResponse[]>(
    []
  )
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedReseller, setSelectedReseller] =
    useState<ResellerListAPIResponse | null>(null)
  const [rechargeTotal, setRechargeTotal] = useState(0)

  const currentUser = useTypedSelector(state => state.currentUser)

  let managerId = ""

  if (managerID === "default") {
    managerId = String(currentUser?.currentUser?.data?.userId) ?? ""
  } else {
    managerId = managerID
  }

  const [filters, setFilters] = useState<Filter>({
    currentPage: 1,
    pageSize: 10,
  })

  const fetchData = async (payload: Payload, initial = false) => {
    try {
      setLoading(true)
      const res = await getResellerList(payload)
      if (initial) {
        setRechargeTotal(parseInt(res.total))
      }
      setResellerList(res.data)
      console.log(res)
    } catch (error) {
      console.error("Error fetching reseller list:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData({ managerId: managerId, filters }, true)
  }, [])

  const toggleStatus = async (
    index: number,
    reseller: ResellerListAPIResponse
  ) => {
    try {
      const newStatus = reseller.status === "ACTIVE" ? "false" : "true"

      await updateResellerStatus({
        resellerId: reseller.user_uuid,
        status: newStatus,
      })

      setResellerList(prevResellerList =>
        prevResellerList.map((item, i) =>
          i === index
            ? { ...item, status: newStatus === "true" ? "ACTIVE" : "IN_ACTIVE" }
            : item
        )
      )
    } catch (error) {
      console.log(error)
    }
  }

  const openModal = (reseller: ResellerListAPIResponse) => {
    setSelectedReseller(reseller)
    setModalOpen(true)
  }

  const closeModal = () => {
    setSelectedReseller(null)
    setModalOpen(false)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    console.log("Form submitted")
    closeModal()
  }

  const handlePageChange: PaginationProps["onChange"] = async pageNumber => {
    const payload: Payload = {
      managerId: managerId,
      filters: {
        ...filters,
        currentPage: pageNumber,
      },
    }
    setFilters(payload.filters)
    await fetchData(payload)
  }

  return (
    <Auxiliary loading={loading}>
      <div>
        <main className="p-sm-4">
          <div
            className="mt-md-3 bg-white"
            style={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}
          >
            <Table hover responsive>
              <thead>
                <tr style={{ backgroundColor: "#f9fafb", color: "#6b7280" }}>
                  <th>Reseller ID</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Created</th>
                  <th>Contact</th>
                  <th>Jems Transferred</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {resellerList.map((reseller, index) => {
                  const isDisabled = reseller.status === "IN_ACTIVE"
                  return (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: isDisabled ? "#f1f5f9" : "",
                        cursor: isDisabled ? "not-allowed" : "pointer",
                        // opacity: isDisabled ? 0.6 : 1,
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>
                        {reseller.user_uuid}
                      </td>
                      <td>{reseller.name}</td>
                      <td>
                        <Switch
                          checked={reseller.status === "ACTIVE"}
                          onChange={(checked, e) => {
                            e.stopPropagation()
                            toggleStatus(index, reseller)
                          }}
                          checkedIcon={false}
                          handleDiameter={20}
                          uncheckedIcon={false}
                          width={45}
                          onColor="#76D571"
                        />
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {dayjs(Number(reseller.created_time)).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </td>
                      <td>{reseller.phone}</td>
                      <td style={{ color: "#f59e0b" }}>
                        {reseller.jems_credited && (
                          <img
                            src="https://saz.myjosh.in/stream-az/prod-ugc-contents/static-images/categories/coin.png"
                            alt="coin-icon"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "8px",
                            }}
                          />
                        )}
                        {reseller.jems_credited}
                      </td>
                      <td
                        style={{
                          color: "#ef4444",
                          cursor: "pointer",
                        }}
                        onClick={e => {
                          e.stopPropagation()
                          openModal(reseller)
                        }}
                      >
                        Recharge
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>

            <div className="d-flex justify-content-end pb-3">
              <Pagination
                className="mt-md-3"
                onChange={handlePageChange}
                current={filters.currentPage}
                total={rechargeTotal < 10000 ? rechargeTotal : 10000}
                pageSize={filters.pageSize}
                showSizeChanger={false}
              />
            </div>
          </div>

          {/* TransferModal component */}
          {modalOpen && selectedReseller && (
            <TransferJemsModal
              modalOpen={modalOpen}
              closeModal={closeModal}
              handleSubmit={handleSubmit}
              selectedReseller={selectedReseller}
              managerId={managerId}
            />
          )}
        </main>
      </div>
    </Auxiliary>
  )
}

export default Recharge
