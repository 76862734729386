import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material"
import { ResellerListAPIResponse } from "util/types/types"

interface ManagerItem {
  userId: number
  email: string
  name: string
}

interface AssignManagerModalProps {
  isOpen: boolean
  toggle: () => void
  managerList: ManagerItem[]
  selectedReseller: ResellerListAPIResponse | null
  onAssignManager: (managerId: string) => void
}

const AssignManagerModal: React.FC<AssignManagerModalProps> = ({
  isOpen,
  toggle,
  managerList,
  selectedReseller,
  onAssignManager,
}) => {
  const [selectedManager, setSelectedManager] = useState<string | null>(null)

  const handleManagerSelect = (event: SelectChangeEvent<string>) => {
    setSelectedManager(event.target.value)
  }

  const handleAssignClick = () => {
    console.log(selectedManager)

    if (selectedManager !== null) {
      onAssignManager(selectedManager)
      toggle()
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        Assign <b>{selectedReseller?.name}</b> to Account Manager
      </ModalHeader>
      <ModalBody>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="manager-select-label">Select Manager</InputLabel>
          <Select
            labelId="manager-select-label"
            value={selectedManager || ""}
            onChange={handleManagerSelect}
            label="Select Manager"
          >
            {managerList.map(manager => (
              <MenuItem key={manager.userId} value={String(manager.userId)}>
                {manager.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAssignClick}
          disabled={!selectedManager}
        >
          Assign
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AssignManagerModal
