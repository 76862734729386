import Axios from "axios"
import moment from "moment"
import toastr from "toastr"

export const activateDeactivateContent = async (
  content,
  action,
  currentUser
) => {
  try {
    let contentMeta = {
      langCode: content.lang_code,
      publishedDate: content.created_date,
      autoModerationLevel: content.auto_moderation_label,
      reviewBucket: content.review_bucket,
      videoDuration: content.video_duration,
      sourceId: content.user_profile.user_uuid,
      rejectReason: content.reject_reason,
      competitor_logo_name: content.competitor_logo_name,
      competitor_logo_name_text: content.competitor_logo_name_text,
      banned_organization_name: content.banned_organization_name,
      targetCategory: content.target_category,
      miscRejectReason: content.miscRejectReason,
      videoSource: content.video_source,
      viewCount: content.view_count,
      likeCount: content.like_count,
      shareCount: content.share_count,
      downloadCount: content.download_count,
      moderationLevel: content.moderation_level,
      moderationStatus: content.moderation_status,
      adsContent: content.adsContent,
      blockedContent: content.blocked_content,
    }

    //TODO:
    //if content is already qc_done we're marking qc as true
    if (
      ((currentUser.permissions &&
        currentUser.permissions.indexOf("VIEW_ACTIVITY") >= 0) ||
        !currentUser.agency) &&
      (content.moderation_level === 2 || content.moderation_level === -1)
    ) {
      contentMeta.qc = true
    }

    let response = await Axios.post(
      `/api/content/action/${action}?contentId=${content.content_uuid}`,
      contentMeta
    )
    return response
  } catch (error) {
    if (error && error.message && error.message.includes("401")) {
      return { message: "User Not Verified!!" }
    }
    return error
  }
}

export const tagContent = async formData => {
  try {
    let data = await Axios.post(`/api/content/updateTargetCategory`, formData)
    return data
  } catch (error) {
    throw error
  }
}

export const updateContent = async payload => {
  try {
    let response = await Axios.post(
      `/api/content/update-caption?contentId=${payload.contentId}`,
      payload.contentData
    )
    toastr.success("Action Successful ...")
    return response
  } catch (error) {
    throw error
  }
}

export const updateContentZones = async formData => {
  try {
    let data = await Axios.post(`/api/content/update/zones`, formData)
    return data
  } catch (error) {
    throw error
  }
}

export const tagNonEnglish = async (content, action) => {
  try {
    let response = await Axios.post(
      `/api/content/tagNonEnglish/${content.content_uuid}`
    )
    return response
  } catch (error) {
    return error
  }
}

export const convertToVertical = async (content, action) => {
  try {
    let response = await Axios.post(
      `/api/content/convertToVertical/${content.content_uuid}`
    )
    return response
  } catch (error) {
    return error
  }
}

export const reProcessedContentModerationAction = async (content, action) => {
  try {
    let data = {
      // TODO: Use a configuration to specify the version at all the places in code
      version: "v6", // content.reprocessed_moderation.keys[0]
      accept: action == "accept" ? "Y" : "N",
      old_moderation_label: content.auto_moderation_label,
      new_moderation_label: content.reprocessed_moderation.v6.moderation_label,
    }
    let response = await Axios.post(
      `/api/content/reProcessedModerationAction/${content.content_uuid}`,
      data
    )
    return response
  } catch (error) {
    return error
  }
}

export const getContentByUUIDs = async contentUUIDs => {
  try {
    let data = await Axios.post(`/api/content/getContentByUUIDs`, {
      contentUUIDs,
    })
    return data
  } catch (error) {
    throw error
  }
}

export const qualityCheckContent = async contentUUID => {
  try {
    let response = await Axios.get(
      `/api/content/updateQcFlag?content_uuid=${contentUUID}&qc_flag=true`
    )
    return response
  } catch (error) {
    throw error
  }
}

export const reviewContent = async contentUUID => {
  try {
    let response = await Axios.get(
      `/api/content/updateReviewedFlag?content_uuid=${contentUUID}&reviewed=true`
    )
    return response
  } catch (error) {
    throw error
  }
}

export const removeDuplicate = async contentUUID => {
  try {
    let response = await Axios.post(
      `/api/content/removeDuplicateContent?contentUUID=${contentUUID}`
    )
    return response
  } catch (error) {
    throw error
  }
}

export const tagContentTaxonomy = async (
  formData,
  sendToQC,
  skipped,
  sendToInternalQC,
  taxonomyDeskId,
  sourceId,
  zoneInfov1
) => {
  try {
    let data = await Axios.post(
      `/api/content/tag/taxonomy?sendToQC=${sendToQC}&skipped=${skipped}&sendToInternalQC=${sendToInternalQC}&taxonomyDeskId=${taxonomyDeskId}&sourceId=${sourceId}`,
      { ...formData, zoneInfov1 }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const sendNotifContentDeactivationAlert = async details => {
  try {
    let data = await Axios.post(
      `/api/content/notifWorthyDeactivationAlert`,
      details
    )
    return data
  } catch (error) {
    throw error
  }
}

export const skipContentTagging = async (formData, contentUUID, langCode) => {
  try {
    let data = await Axios.post(
      `/api/content/tag/skip?langCode=${langCode}&contentUUID=${contentUUID}`,
      formData
    )
    return data
  } catch (error) {
    throw error
  }
}

export const markQcTaskAsCompleted = async (
  payload,
  sendToInternalQC,
  sourceId
) => {
  try {
    let data = await Axios.post(
      `/api/content/tag/markQcComplete?sendToInternalQC=${sendToInternalQC}&sourceId=${sourceId}`,
      payload
    )
    return data
  } catch (error) {
    throw error
  }
}

export const markTaskAsCompleted = async (payload, sourceId) => {
  try {
    let data = await Axios.post(
      `/api/content/tag/markTaskComplete?sourceId=${sourceId}`,
      payload
    )
    return data
  } catch (error) {
    throw error
  }
}
export const markDTATaskAsCompleted = async (payload, sourceId) => {
  try {
    let data = await Axios.post(
      `/api/content/dtataskcomplete?sourceId=${sourceId}`,
      payload
    )
    return data
  } catch (error) {
    throw error
  }
}

export const addContentToQcLogs = async payload => {
  try {
    let data = await Axios.post(`/api/content/addContentToQcLogs`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const updateTrending = async payload => {
  try {
    let data = await Axios.post(`/api/content/cms-update`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const labelContent = async (payload, contentId) => {
  try {
    let data = await Axios.post(
      `/api/content/label?contentId=${contentId}`,
      payload
    )
    return data
  } catch (error) {
    toastr.error("Error in labelling content")
    throw error
  }
}

export const enlistContentAsShoppable = async payload => {
  try {
    let data = await Axios.post(`/api/content/shoppable/enlist`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const markContentAsUnShoppable = async payload => {
  try {
    let data = await Axios.post(
      `/api/content/shoppable/update-shoppable-content`,
      payload
    )
    return data
  } catch (error) {
    throw error
  }
}

export const restoreContent = async payload => {
  try {
    let data = await Axios.post(`/api/content/restore`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const bulkUploadContentForBoosting = async formData => {
  toastr.success("Action Started!!")
  try {
    let data = await Axios.post(`/api/content/boost/bulk`, formData)
    return data
  } catch (error) {
    toastr.error("Error in boosting the content")
    throw error
  }
}

export const sendNotificationContentDeactivationAlert = async payload => {
  try {
    let data = await Axios.post(
      `/api/content/notifWorthyDeactivationAlert`,
      payload
    )
    toastr.success("Action Successfull!!", "")
    return data
  } catch (error) {
    throw error
  }
}

export const getEvents = async () => {
  try {
    let data = await Axios.get(`/api/event/get`)
    return data
  } catch (error) {
    throw error
  }
}

export const getAgencyData = async (filters, agency) => {
  const startEpoch = filters.createdDateFrom
    ? filters.createdDateFrom
    : Date.parse(moment().subtract(7, "days").calendar().valueOf())
  const startDate = moment(parseInt(startEpoch)).format("YYYY-MM-DD")
  const endtEpoch = filters.createdDateTo ? filters.createdDateTo : Date.now()
  const endDate = moment(parseInt(endtEpoch)).format("YYYY-MM-DD")
  try {
    let data = await Axios.post(
      `/api/content/tag/taskStats?startDate=${startDate}&endDate=${endDate}`,
      { agency }
    )
    return data
  } catch (error) {
    throw error
  }
}
export const activateContent = async formData => {
  toastr.success("Action Started!!")
  try {
    let data = await Axios.post(`/api/content/action/bulk/activate`, formData)
    return data
  } catch (error) {
    toastr.error("Error in activating content")
    throw error
  }
}
export const deactivateContent = async formData => {
  toastr.success("Action Started!!")
  try {
    let data = await Axios.post(`/api/content/action/bulk/deactivate`, formData)
    return data
  } catch (error) {
    toastr.error("Error in deactivating content")
    throw error
  }
}

export const getAdsStatus = async formData => {
  try {
    let data = await Axios.post(`/api/content/getAdsStatus`, formData)
    return data?.data
  } catch (error) {
    throw error
  }
}

export const requestContentByFilters = async payload => {
  try {
    const filters = payload
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"] ? payload["pageSize"] : 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/embedding/list?offset=${offset}&limit=${limit}`,
      {
        filters: payload,
      }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestEmbeddingGroupBy = async payload => {
  try {
    const filters = payload
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"] ? payload["pageSize"] : 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/embedding/getSubTypeByFilters?offset=${offset}&limit=${limit}`,
      {
        filters: payload,
      }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const getDuplicateSkipTags = async () => {
  try {
    let data = await Axios.get(`/api/content/getDuplicateSkipTags`)
    return data?.data?.keys
  } catch (error) {
    throw error
  }
}

export const updateDuplicateSkipTags = async payload => {
  try {
    let data = await Axios.post(`/api/content/updateDuplicateSkipTags`, {
      tags: payload,
    })
    toastr.info("Tags Updated Successfully.")
    return data
  } catch (error) {
    // console.error("Error: While Updating the Tags",error)
    toastr.error(`Error: While Updating the Tags! ${error.message}`)
    // throw error
  }
}
