import PropTypes from "prop-types"
import React, { useState, createRef, useEffect } from "react"
import { Card, Row, CardBody, Col, UncontrolledTooltip } from "reactstrap"
import { Button, Rating } from "@mui/material"
import { capitalizeFirstLetter, formatNumber, msToHMS } from "../../util/common"
import { CURATED_TYPE_MAPPING } from "constants/uiConstants"
import { FaShare } from "react-icons/fa"
import { AiTwotoneHeart, AiFillEye } from "react-icons/ai"
import { Chip, Stack } from "@mui/material"
import "./style.css"
import "./creatorInfo.css"
import { Tooltip, Tag, Modal, Pagination } from "antd"
import toastr from "toastr"
import CopyToClipboard from "react-copy-to-clipboard"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { diamondsByUsers, getLiveCallingLogData, getLiveCallingUserData, updateStatus } from "clientServices/liveCallingService"
import { MdEdit, MdOutlineAdd } from "react-icons/md"
import { AiOutlineStop } from "react-icons/ai"
import { FaPlus } from "react-icons/fa6"
import { getLocationData } from "appRedux/slices/location"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import _ from "lodash"

import { RootState } from "appRedux/store/configureStore"
interface Props extends RouteComponentProps {
  item: {
    user_uuid: string
    profile_pic: string
    user_name: string
    live_call_enabled: Boolean
  }

  queryParams: { [key: string]: string | number }
}
interface UserInfo {
  profile_pic: string
  user_name: string
  user_uuid:string
  age: string
  gender: string
  interests: Array<string>
  location: Array<string>
  audio_call: { price: string,discount_price:string }
  agency_id: string
  video_enabled: Boolean
  free_call_enabled: Boolean
  verified: Boolean
  created_date: string
  live_call_enabled: Boolean
  last_online_date:string
  avg_rating:number
  total_rating_count:number
  status: string
  // Add any other properties that you expect in the response from getLiveCallingUserData
}

interface AcceptanceStats {
  avg_rating: number;
  call_acceptance_label: string;
  call_acceptance_rate: number;
  today_earnings_text: string;
  total_earnings: number;
  total_earnings_text: string;
  total_rating_count: number;
  total_time_spent: number;
}

interface UserLogInfo {
   
   total:number;
   duration_sum:{
    value:number
   }
   logData:Array<LogObject>
   acceptanceStats: AcceptanceStats
  
    // Add any other properties that you expect in the response from getLiveCallingUserData
  }

  interface LogObject{

    id:string
    created_date:string
    comm_type:string
    caller_id:string
    duration:number
    call_type:string
    rating:number;
    end_reason:string
    fc_unit:number
    refund:boolean
  }
  
const interestList:{ [key: string]: string }={"yoga":"Yoga","gym":"Gym","fish_lover":"Fish lover","dog":"Dog","cat":"Cat","beach_lover":"Beach lover","mountain_lover":"Mountain lover","road_trips":"Road trips","casual_friendship":"Casual friendship","casual_relationship":"Casual relationship","astrology":"Astrology","dancing":"Dancing","singing":"Singing","reading_books":"Reading books","hiking":"Hiking","camping":"Camping","cooking":"Cooking","beer_lover":"Beer lover","writing":"Writing","video_games":"Video Games","chai_lover":"Chai lover","coffee_lover":"Coffee lover","horror_movies":"Horror movies","romantic_movies":"Romantic movies","comedy_movies":"Comedy movies","gardening":"Gardening","photography":"Photography","shopping":"Shopping","football":"Football","cricket":"Cricket","hockey":"Hockey","swimming":"Swimming"}


const CreatorInfo = (props: Props) => {
  const [userUUid, setUserUUID] = useState(props.location.search.split("=")[1])
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)
  const [userInfoLog, setUserInfoLog] = useState<UserLogInfo | null>(null)
  const [showAddVideosPopup, setShowAddVideosPopup] = useState<any>(false)
  const[currentPage,setCurrentPage]=useState(1)
 const[loading,setLoading]=useState(false)
 const [cityLocationList, setCityLocationList] = useState<{ [key: string]: string }>({});
const[diamondsPerUser,setDiamondPerUser]=useState<{ [key: string]: string }>({});
 const dispatch = useDispatch()
 const locationData = useSelector(
  (state: RootState) => state.locationReducer.locationList
)
  const _getModerationImage = (props: Props) => {
    return userInfo?.profile_pic
  }
  useEffect(() => {
    dispatch(getLocationData());
    ;(async () => {
      let res = await getLiveCallingUserData({ filters: { id: userUUid } })
      setUserInfo(res.data[0])
      let log = await getLiveCallingLogData({ filters: { id: userUUid,currentPage } })
      setUserInfoLog({...log.data,...{total:log.total},...{logData:log.itemData?.data}, ...{acceptanceStats:log.acceptanceStats}})
   let diamondsPUser:any=await diamondsByUsers([userUUid]);
 console.log(diamondsPUser)
    setDiamondPerUser(diamondsPUser[userUUid])
  })()
  }, [loading])
  useEffect(() => {
    if (locationData) {
      let cityLocationList:any={}
      locationData.map(
        (location: { name: string; dhTagId: string; children: [] }) => {
         
          cityLocationList[location.dhTagId]=capitalizeFirstLetter(location.name)
         
        }
      )

      console.log(cityLocationList)
       setCityLocationList(cityLocationList)
    }
  }, [locationData])
  const manageStatus = async (live_call_enabled: Boolean) => {
    await updateStatus({ live_call_enabled, user_uuid: userInfo?.user_uuid })
    setLoading(!loading)
    setShowAddVideosPopup(false)
  }
  const handleEdit=()=>{
    if (userInfo?.user_uuid) {
        props.history.push(`/live/creators/manage?creator_id=` + userInfo.user_uuid)
       
      } else {
        console.error("User UUID is undefined.")
      }
  }
  const handleModal = (status: Boolean) => {
    setShowAddVideosPopup(status)
  }
  const _renderStatusModal = () => {
    return (
      <Modal
        width={700}
        title="Update Status"
        open={showAddVideosPopup}
        footer={null}
        onCancel={() => {
          setShowAddVideosPopup(false)
        }}
        destroyOnClose
      >
        <div>{`Do you want to ${
          userInfo?.live_call_enabled == false ? "enable" : "disable"
        } the creator from receiving audio calls?`}</div>
        <div className="d-flex justify-content-end mt-2">
          <Button
            onClick={() => {
              manageStatus(userInfo?.live_call_enabled == false ? true : false)
            }}
            variant="contained"
            className="mx-1"
            color="primary"
            size="small"

            // size="small"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              handleModal(false)
            }}
            variant="contained"
            className="mx-1"
            color="primary"
            size="small"

            // size="small"
          >
            Cancel
          </Button>
        </div>
      </Modal>
    )
  }
  const _onPageChange = async(value:any) => {
   
   setCurrentPage(value);
    let log = await getLiveCallingLogData({ filters: { id: userUUid,currentPage:value } })
      setUserInfoLog({...log.data,...{total:log.total},...{logData:log.itemData?.data}, ...{acceptanceStats:log.acceptanceStats}})
   
  }

  const {
    currentUser: { data },
  } = useSelector((state:RootStateOrAny) => state.currentUser)
  const userPermissions = _.values(data.jotPermissions)
  
  return (
    <div>
      <Card
        className={`text-center shadow-lg`}
        style={{ borderRadius: "10px" }}
      >
       <Row>
        <Col md={10}></Col>
        <Col md={2}> <Button
              color="info"
              variant="contained"
              style={{
                width: "75%",
                marginTop:'10px',
                marginBottom:'10px',
                
              }}
              onClick={()=>{
                props.history.push('/live/creators/browse')
              }}
              size="medium"
              
            >
              Back
            </Button></Col>
      
       </Row>
        <Row>
          <Col className="align-self-end" style={{ width: "100%" }}>
            {/* {_renderThumbnail(props)} */}
            {
              <div style={{}}>
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                   style={{
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                    justifyContent: "space-between",
                  }}>
                    <img
                      src={_getModerationImage(props)}
                      className="rounded-circle avatar-xs"
                      style={{ height: "3rem", width: "3rem" }}
                      alt="user-pic"
                    />
                    <div
                      style={{
                        fontSize: "14px",
                        marginLeft: "10px",
                        marginTop: "4px",
                      }}
                    >
                      <b>{userInfo?.user_name}</b>
                      <div
                        style={{
                          fontSize: "14px",
                          marginLeft: "10px",
                          marginTop: "2px",
                        }}
                      >
                        {userInfo?.created_date?.split("T")[0]
                          .split("-")
                          .reverse()
                          .join("/")}
                        , {userInfo?.created_date?.split("T")[1].split("+")[0]}
                      </div>
                    </div>
                 {userInfo?.avg_rating &&<div style={{margin:'auto', marginLeft:'20px',display:'flex'}}><Rating name="half-rating" defaultValue={userInfo?.avg_rating} precision={0.1} readOnly /> <div style={{marginTop:'2px', marginLeft:'5px'}}>({userInfo?.avg_rating}) - ({userInfo?.total_rating_count}) <span style={{fontSize:'10px'}}>Rating</span></div></div>  }  
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: userPermissions.includes("EDIT_AUDIO_CALL_PRICING") ? "20%" : '10%',
                      height: "max-content",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      color="info"
                      variant="contained"
                      startIcon={<MdEdit />}
                      style={{
                        width: "98%",
                        margin: "10px 10px",
                      }}
                      onClick={
                        handleEdit
                      
                  }
                      size="small"
                      type="submit"
                    >
                      Edit
                    </Button>
                    {userPermissions.includes("EDIT_AUDIO_CALL_PRICING") && (
                    <Button
                      color={userInfo?.live_call_enabled ? 'error' : 'success'}
                      variant="contained"
                      startIcon={
                        userInfo?.live_call_enabled ? (
                          <AiOutlineStop />
                        ) : (
                          <FaPlus />
                        )
                      }
                      onClick={() => handleModal(true)}
                      style={{
                        width: "98%",
                        margin: "10px 10px",
                      }}
                      size="small"
                      type="submit"
                    >
                     {userInfo?.live_call_enabled ? 'Disable' : 'Enable'}
                    </Button>
                    )}
                  </div>
                </div>
              </div>
            }
            {
              <>
                {" "}
          <div style={{margin:"25px"}}>
                 <div
                  className="created-by"
                  style={{
                    fontWeight: "700",
                    padding: "14px 17px 5px 17px",
                    backgroundColor: "unset",
                  }}
                >
                  <div className="d-flex align-items-center font-size-14 justify-content-between">
                    <div style={{ fontSize: "18px",marginTop:'20px' }}>Creator Details</div>
                  </div>
                </div>
                <div className="grid-container">
                  <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between">
                      <div>Age</div>
                      <div style={{ fontWeight: "600" }}>{userInfo?.age}</div>
                    </div>
                  </div>
                  <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between">
                      <div>Gender</div>
                      <div style={{ fontWeight: "600" }}>
                        {userInfo?.gender}
                      </div>
                    </div>
                  </div>
                  <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between">
                      <div>Interests</div>
                      <div style={{ fontWeight: "600" }}>
                        {userInfo?.interests?.map(item=>interestList[item])?.join(", ")}
                      </div>
                    </div>
                  </div>
                  <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between">
                      <div>Location</div>
                      <div style={{ fontWeight: "600" }}>
  {Array.isArray(userInfo?.location) && userInfo?.location[0]
    ? cityLocationList[userInfo?.location[0]] || ""
    : "Location Not Available"}
</div>
                    </div>
                  </div>
                  <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between">
                      <div>Price/min</div>
                      <div style={{ fontWeight: "600" }}>
                        {userInfo?.audio_call?.discount_price}
                      </div>
                    </div>
                  </div>
                  <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between">
                      <div>Agency ID</div>
                      <div style={{ fontWeight: "600" }}>
                        {userInfo?.agency_id}
                      </div>
                    </div>
                  </div>
                  <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between">
                      <div>Eligible for free calls</div>
                      <div style={{ fontWeight: "600" }}>
                        {userInfo?.free_call_enabled ? "Y" : "N"}
                      </div>
                    </div>
                  </div>
                  <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between">
                      <div>Blue tick enabled</div>
                      <div style={{ fontWeight: "600" }}>
                        {userInfo?.verified ? "Y" : "N"}
                      </div>
                    </div>
                  </div>
                  <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between" >
                      <div>Call Acceptance Rate</div>
                      <Tooltip title={userInfoLog?.acceptanceStats?.call_acceptance_label}>
                        <b>{userInfoLog?.acceptanceStats?.call_acceptance_rate
                        ? (
                            userInfoLog?.acceptanceStats?.call_acceptance_rate * 100
                          ).toFixed(2) + " %"
                        : "0%"}</b>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between" >
                      <div>Call Status</div>
                      <Tooltip title={userInfo?.status}>
                        <b>{userInfo?.status}</b>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                

                <div
                  className="created-by"
                  style={{
                    fontWeight: "700",
                    padding: "14px 17px 5px 17px",
                    backgroundColor: "unset",
                  }}
                >
                  <div className="d-flex align-items-center font-size-14 justify-content-between">
                    <div style={{ fontSize: "18px",marginTop:'20px' }}>Call History</div>
                  </div>
                </div>
                <div className="grid-container">
                <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between">
                      <div>Total Duration Of Calls</div>
                      <div style={{ fontWeight: "600" }}>
                        {userInfoLog?.duration_sum?.value ? msToHMS(userInfoLog?.duration_sum?.value) : "-:-:-"}
                      </div>
                    </div>
                  </div>
                  <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between">
                      <div>Last Online Time</div>
                      <div style={{ fontWeight: "600" }}>{userInfo?.last_online_date?.split("T")[0]
                          .split("-")
                          .reverse()
                          .join("/")} 
                        &nbsp;| {userInfo?.last_online_date?.split("T")[1]?.split("+")[0]}</div>
                    </div>
                  </div>
                  <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between">
                      <div>Total Calls Done</div>
                      <div style={{ fontWeight: "600" }}>
                        {userInfoLog?.total}
                      </div>
                    </div>
                  </div>
                  
                  <div className="created-by">
                    <div className="d-flex align-items-center font-size-14 justify-content-between">
                      <div>Total Diamonds Earned</div>
                      <div style={{ fontWeight: "600" }}>
                        {diamondsPerUser?.total_diamonds_count}
                      </div>
                    </div>
                  </div>
                 
                </div>

<table className="creatorTable">
  <thead>
    <tr className="tableRow">
      <th>Date</th>
      <th>Time</th>
      <th>Call Format</th>
      <th>Call Type</th>
      <th>Caller Id</th>
      <th>Call Id</th>
      <th>Duration of Call</th>
      <th>Free Calls</th>
      <th>Call end Reason</th>
      <th>Rating</th>
      <th>ReFunded</th>
    </tr>
  </thead>
  <tbody>
   
    {userInfoLog?.logData?.map((items:LogObject)=>(
               <tr className="tableRow">  <td>{items?.created_date?.split("T")[0].split("-").reverse().join('/')}</td>
               <td>{items?.created_date?.split("T")[1].split("+")[0]}</td>
                <td>{items.comm_type}</td>
                <td>{items.call_type}</td>
                <td style={{whiteSpace: "nowrap"}}>{items.caller_id}</td>
                <td style={{whiteSpace: "nowrap"}}>{items.id}</td>
                <td>{ items.duration ? msToHMS(items.duration) : "0:0:0"}</td>
                <td>{items.fc_unit}</td>
                <td>{items.end_reason || "-"}</td>
               <td>{items.rating || '-'}</td>
               <td>{items.refund? "Yes" : "No" || "-"}</td>
                </tr>
                ))}
   
  </tbody>
  
</table>
</div>    
<div className="d-flex justify-content-end">
                  <Pagination
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                    onChange={_onPageChange}
                    defaultCurrent={currentPage}
                    total={userInfoLog?.total ? userInfoLog?.total : 10}
                    pageSize={10}
                    showSizeChanger={false}
                  />
                </div>
              </>
            }
          </Col>
        </Row>

        <CardBody style={{ padding: ".5rem", fontSize: "18px" }}></CardBody>
      </Card>
      {_renderStatusModal()}
    </div>
  )
}

export default withRouter(CreatorInfo)
