import React, { useState, useEffect } from "react"
import { getAccountManagerList } from "clientServices/joshResellerService"
import { Select } from "antd"
import { AccountManagerAPIResponse } from "util/types/types"

import Auxiliary from "util/Auxiliary"
import Transactions from "./Transactions"

const { Option } = Select

const AllTransactions: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [managerList, setManagerList] = useState<AccountManagerAPIResponse[]>(
    []
  )
  const [selectedManagerId, setSelectedManagerId] = useState<number | null>(
    null
  )

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const managers = await getAccountManagerList()
        setManagerList(managers.data)
      } catch (error) {
        console.error("Error fetching account manager list:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <Auxiliary loading={loading}>
      <div style={{ margin: "0 30px" }}>
        <b>Account Manager: </b>
        <Select
          placeholder="Select Account Manager"
          allowClear={true}
          value={selectedManagerId}
          onChange={value => setSelectedManagerId(value)}
          style={{ width: "20%", maxWidth: "300px", marginLeft: "5px" }}
        >
          {managerList.map(manager => (
            <Option key={manager.userId} value={manager.userId}>
              {manager.name}
            </Option>
          ))}
        </Select>
      </div>

      {selectedManagerId ? (
        <Transactions
          key={String(selectedManagerId)}
          managerId={String(selectedManagerId)}
        />
      ) : (
        <p className="text-center p-sm-5">
          Please select a manager to view transactions.
        </p>
      )}
    </Auxiliary>
  )
}

export default AllTransactions
