import Axios from "axios"
import { string } from "prop-types"
import toastr from "toastr"

interface Filter {
  currentPage: number
  pageSize: number
}

interface Payload {
  managerId?: string
  filters: Filter
  unassigned?: boolean
}

interface Invoice {
  location: string
  invoiceId: string
}

interface ResellerStatus {
  resellerId: string
  status: string | undefined
}

export const getResellerList = async (payload: Payload) => {
  try {
    const offset = payload.filters.currentPage
      ? payload.filters.currentPage - 1
      : 0
    const limit = payload.filters.pageSize ? payload.filters.pageSize : 10

    let url = `/api/reseller/list?managerId=${payload.managerId}&limit=${limit}&offset=${offset}`

    if (payload.unassigned === true) {
      url = `/api/reseller/list?limit=${limit}&offset=${offset}&unassignedResellers=true`
    }

    const data = await Axios.get(url)

    return data.data
  } catch (error) {
    toastr.error("Error getting Reseller List "+String(error))
    throw error
  }
}

export const getTransactionList = async (payload: Payload) => {
  try {
    const currentPage = payload.filters.currentPage
      ? payload.filters.currentPage - 1
      : 0
    const pageSize = payload.filters.pageSize ? payload.filters.pageSize : 10

    const data = await Axios.get(
      `/api/reseller/transactions?managerId=${payload.managerId}&pageSize=${pageSize}&currentPage=${currentPage}`
    )

    return data.data
  } catch (error) {
    toastr.error("Error getting Transactions List "+String(error))
    throw error
  }
}

export const transferJems = async (formData: FormData) => {
  try {
    const response = await Axios.post(`/api/reseller/transferJems`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    toastr.success("Transfer successful")
    return response
  } catch (error) {
    toastr.error("Transfer Jems API error: "+String(error))
    throw error
  }
}

export const getTransactionInvoice = async (payload: Invoice) => {
  try {
    const location = encodeURIComponent(payload.location)
    const data = await Axios.get(
      `/api/reseller/invoice/download?invoiceId=${payload.invoiceId}&location=${location}`
    )
    return data.data
  } catch (error) {
    toastr.error("Unable to get transaction invoice "+String(error))
    throw error
  }
}

export const getAccountManagerList = async () => {
  try {
    const data = await Axios.get(`/api/reseller/getAccountManagersList`)

    return data
  } catch (error) {
    toastr.error("Error getting Account Manager List:"+String(error))
    throw error
  }
}

export const updateResellerStatus = async (payload: ResellerStatus) => {
  try {
    const data = await Axios.post(`/api/reseller/updateStatus`, payload)
    return data
  } catch (error) {
    toastr.error("Error updating Reseller Status:"+String(error))
    throw error
  }
}

export const addResellerUser = async (payload: {
  user_uuid: string
  leadId: string
}) => {
  try {
    const data = await Axios.post(`/api/reseller/addUser`, payload)
    toastr.success("User mapping created successfully!")
    return data
  } catch (error) {
    toastr.error("Unable to assign Manager :"+String(error))
    throw error
  }
}
