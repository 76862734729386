import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Upload } from "antd"
import toastr from "toastr"
import _ from "lodash"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import { Form } from "react-bootstrap"
import ValidatorForm from "../ValidatorForm"
import moment from "moment"
import "moment-timezone"

export default class packageCollectionEntityForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      discount_percentage: this.props?.data?.discount_percentage || null,
      discounted_price: this.props?.data?.discounted_price || null,
      status: this.props?.data?.status || "ACTIVE",
      view_order: this.props?.data?.view_order ?? null,
    }
  }

  componentWillUnmount() {
    this._resetState()
  }

  _resetState() {
    this.setState({
      title: "",
      orig_price: null,
      discount_percentage: null,
      discounted_price: null,
      jems_count: null,
    })
  }

  _onImageRemove = type => {
    if (type === "thumbnail") {
      this.setState({
        package_thumbnail_url_list: [],
        thumbnailImage: null,
      })
    }
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      if (type === "thumbnail") {
        this.setState({
          package_thumbnail_url_list: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          thumbnailImage: fileObj.file,
        })
      }
    }
  }

  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>{`Update Package Entity`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm={12} md={4} className="mt-2">
                <label>Title</label>
                <input
                  className="form-control"
                  value={this.props?.data?.title || ""}
                  disabled={true}
                />
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Status</label>
                <div>
                  <select
                    className="form-select"
                    onChange={e => this.setState({ status: e.target.value })}
                    value={this.state.status}
                  >
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </select>
                </div>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Original Price</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.props?.data?.orig_price || null}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Discount Percentage (%)</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.discount_percentage}
                    onChange={e => {
                      // if (
                      //   e.target.value &&
                      //   (e.target.value < 0 || e.target.value > 100)
                      // ) {
                      //   toastr.error(
                      //     "Percentage should be between 0 to 100 ..."
                      //   )
                      //   return
                      // }
                      // let discounted_price =
                      //   this.state.discounted_price ||
                      //   this.props?.data?.orig_price
                      // if (e.target.value && this.props?.data?.orig_price) {
                      //   discounted_price =
                      //     ((100 - e.target.value) *
                      //       this.props?.data?.orig_price) /
                      //     100
                      // }
                      // if (!e.target.value) {
                      //   discounted_price = this.props?.data?.orig_price
                      // }
                      this.setState({
                        discount_percentage: e.target.value,
                        // discounted_price,
                      })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Discounted Price</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.discounted_price}
                    onChange={e => {
                      // if (
                      //   e.target.value &&
                      //   (e.target.value < 0 || e.target.value > 100)
                      // ) {
                      //   toastr.error(
                      //     "Percentage should be between 0 to 100 ..."
                      //   )
                      //   return
                      // }
                      // let discounted_price =
                      //   this.state.discounted_price ||
                      //   this.props?.data?.orig_price
                      // if (e.target.value && this.props?.data?.orig_price) {
                      //   discounted_price =
                      //     ((100 - e.target.value) *
                      //       this.props?.data?.orig_price) /
                      //     100
                      // }
                      // if (!e.target.value) {
                      //   discounted_price = this.props?.data?.orig_price
                      // }
                      this.setState({
                        discounted_price: e.target.value,
                        // discounted_price,
                      })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>View Order Price</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.view_order}
                    onChange={e => {
                      this.setState({
                        view_order: e.target.value,
                      })
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="flex" style={{ marginTop: "10px" }}>
                  <div className="d-flex justify-content-end">
                    <Button htmlType="submit" color="primary">
                      Update
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.onCancel()
                        this._resetState()
                      }}
                      color="danger"
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    const payload = {}
    const { data: packageEntityData = {} } = this.props || {}
    const { package_entity_uuid } = packageEntityData || {}
    const { discount_percentage, discounted_price, status, view_order } =
      this.state

    if (package_entity_uuid) {
      payload.package_entity_uuid = package_entity_uuid
    }

    if (status) {
      payload.status = status
    }

    if (discount_percentage || discount_percentage == 0) {
      payload.discount_percentage = +discount_percentage
    }
    if (discounted_price || discounted_price == 0) {
      payload.discounted_price = +discounted_price
    }
    if (view_order || view_order == 0) {
      payload.view_order = +view_order
    }
    this.props.onSubmit(payload)
    this._resetState()
  }

  render() {
    return this._form()
  }
}
