import React, { useState, useEffect } from "react"
import {
  getResellerList,
  getAccountManagerList,
  addResellerUser,
} from "clientServices/joshResellerService"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material"
import {
  IoCaretUpCircleSharp,
  IoCaretDownCircleSharp,
  IoAddCircleOutline,
} from "react-icons/io5"
import AssignManagerModal from "components/JoshReseller/AssignManagerModal"
import Recharge from "./Recharge"
import { Avatar } from "antd"

import Auxiliary from "util/Auxiliary"
import {
  AccountManagerAPIResponse,
  ResellerListAPIResponse,
} from "util/types/types"

const AccountManager: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [managerList, setManagerList] = useState<AccountManagerAPIResponse[]>(
    []
  )
  const [resellerList, setResellerList] = useState<ResellerListAPIResponse[]>(
    []
  )
  const [selectedManagerId, setSelectedManagerId] = useState<number | null>(
    null
  )
  const [selectedReseller, setSelectedReseller] =
    useState<ResellerListAPIResponse | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const payload = {
          filters: {
            currentPage: 1,
            pageSize: 40,
          },
          unassigned: true,
        }

        const res = await getResellerList(payload)
        const managers = await getAccountManagerList()

        setManagerList(managers.data)
        setResellerList(res.data)
      } catch (error) {
        console.error("Error fetching reseller list:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const handleAssignManager = async (managerId: string) => {
    if (selectedReseller) {
      try {
        setLoading(true)
        const userDetails = {
          leadId: managerId,
          user_uuid: selectedReseller.user_uuid,
        }
        await addResellerUser(userDetails)
        setResellerList(prevList =>
          prevList.filter(
            reseller => reseller.user_uuid !== selectedReseller.user_uuid
          )
        )
      } catch (error) {
        console.error("Failed to assign user")
      } finally {
        setLoading(false)
      }
      toggleModal()
    }
  }

  const handleManagerClick = (managerId: number) => {
    setSelectedManagerId(prev => (prev === managerId ? null : managerId))
  }

  return (
    <Auxiliary loading={loading}>
      <h2>Manager/Reseller List</h2>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead sx={{ backgroundColor: "#f9fafb", color: "#6b7280" }}>
              <TableRow>
                <TableCell className="text-center" sx={{ fontWeight: "600" }}>
                  #
                </TableCell>
                <TableCell sx={{ fontWeight: "600" }}>
                  Manager/Reseller Name
                </TableCell>
                <TableCell sx={{ fontWeight: "600" }}>Contact</TableCell>
                <TableCell sx={{ fontWeight: "600" }}>Type</TableCell>
                <TableCell sx={{ fontWeight: "600" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Manager Rows */}
              {managerList.map(manager => (
                <React.Fragment key={manager.userId}>
                  <TableRow>
                    <TableCell sx={{ width: "20px" }}>
                      <Button
                        variant="text"
                        onClick={e => {
                          e.stopPropagation()
                          handleManagerClick(manager.userId)
                        }}
                      >
                        {selectedManagerId === manager.userId ? (
                          <IoCaretUpCircleSharp size="1.5rem" />
                        ) : (
                          <IoCaretDownCircleSharp size="1.5rem" />
                        )}
                      </Button>
                    </TableCell>
                    <TableCell>{manager.name}</TableCell>
                    <TableCell>{manager.email}</TableCell>
                    <TableCell>Manager</TableCell>
                    <TableCell>{/* Manager actions */}</TableCell>
                  </TableRow>
                  {/* Resellers */}
                  {selectedManagerId === manager.userId && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Recharge
                          managerID={String(selectedManagerId)}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
              {/* Unassigned Resellers */}
              {resellerList.map(reseller => (
                <TableRow key={reseller.user_uuid}>
                  <TableCell className="text-sm-center">
                    <Avatar src={reseller.profile_pic} size="small" />
                  </TableCell>
                  <TableCell>{reseller.name}</TableCell>
                  <TableCell>{reseller.phone}</TableCell>
                  <TableCell>Unassigned Reseller</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        setSelectedReseller(reseller)
                        toggleModal()
                      }}
                    >
                      <IoAddCircleOutline size="1rem" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <AssignManagerModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          managerList={managerList}
          selectedReseller={selectedReseller || null}
          onAssignManager={handleAssignManager}
        />
      </Paper>
    </Auxiliary>
  )
}

export default AccountManager
