import React, { useEffect, useState } from "react"
import {
  getDuplicateSkipTags,
  updateDuplicateSkipTags,
} from "clientServices/contentService"
import Auxiliary from "util/Auxiliary"
import { Button, Card, Col, Row } from "reactstrap"
import EditableList from "components/EditableList/editableList"

export default function manageDuplicateSkipTags(props) {
  const [skipTags, setSkipTags] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    const resp = await getDuplicateSkipTags()
    setSkipTags(resp)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  console.log(skipTags)
  return (
    <Auxiliary loading={loading}>
      <Card style={{ padding: "15px 8px" }}>
        <EditableList
          listItems={skipTags}
          onChange={value => {
            setSkipTags(value)
          }}
        />
        <div
          className="d-flex justify-content-end text-sm-end"
          style={{ paddingBottom: "15px", marginTop: "20px" }}
        >
          <Button
            color="primary"
            variant="contained"
            className="font-16 btn-block btn btn-primary"
            onClick={async () => await updateDuplicateSkipTags(skipTags)}
          >
            Submit
          </Button>
        </div>
      </Card>
    </Auxiliary>
  )
}
